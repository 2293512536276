import React, {useEffect, useState} from 'react'
import '../css/job.css'
import {useLazyJobDetailsQuery} from '../../../Api/allApi'
import Swal from 'sweetalert2'

const JobDetails = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const progress = queryParams.get('progress')
  const [jobdetailsTrigger, result] = useLazyJobDetailsQuery()
  const {isSuccess, isFetching} = result
  const [jobApplied, Result] = useLazyJobDetailsQuery()
  const {isSuccess: isJSuccess, isFetching: isjFetching} = Result
  useEffect(() => {
    jobdetailsTrigger(progress)
  }, [jobdetailsTrigger, progress])
  const [detail, setDetails] = useState()
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setDetails(result?.data)
    }
  }, [isSuccess, isFetching, result])
  useEffect(() => {
    if (isJSuccess && !isjFetching) {
      Swal.fire('Applied', Result?.data?.details, 'success')
    }
  }, [isjFetching, isJSuccess, Result])
  function applied(id) {
    jobApplied(id)
  }
  return (
    <>
      {isFetching ? (
        <div className='col-12 text-center card'>
          <i
            className='fa-solid fa-circle-notch  fa-spin  p-10 text-primary'
            style={{fontSize: '30px', color: 'unset'}}
          ></i>
        </div>
      ) : (
        <>
          <div className='card mb-5'>
            <div className='card-body'>
              <h3>Job Details</h3>
            </div>
          </div>
          <div className='card mb-5'>
            <div className='card-body'>
              <div className='border border-1 border-secondary rounded-4 py-10 px-5 my-5'>
                <div
                  className='row justify-content-around align-items-center '
                  style={{cursor: 'pointer'}}
                >
                  <div className='col-md-2 col-sm-12   text-start'>
                    <div className='job-image mx-auto' style={{height: '100%', width: '100%'}}>
                      <img
                        src={detail?.companyDetails?.companyIcon}
                        alt='job'
                        className='h-100 w-100 '
                      />
                    </div>
                  </div>
                  <div className='col-md-10 col-sm-12  my-5 my-lg-0 '>
                    <div className='d-flex justify-content-between flex-wrap align-items-center '>
                      <h4 className='fs-4'>{detail?.companyName}</h4>
                      <span className='fs-6'>
                        <span
                          className='mx-2 text-light px-2 fw-bold rounded'
                          style={{backgroundColor: `${detail?.jobTagHex}`}}
                        >
                          {detail?.jobTag}
                        </span>
                      </span>
                    </div>
                    <span className='fw-bold fs-2'>{detail?.title}</span>
                    <div>
                      <div className='row'>
                        <div className='col-lg-3 col-sm-6'>
                          <i className='bi bi-geo-alt'></i>
                          <span className='fs-6 mx-1'>{detail?.location}</span>
                        </div>
                        <div className='col-lg-3 col-sm-6'>
                          <i className='bi bi-clock'></i>
                          <span className='fs-6 mx-1'>{detail?.contractType}</span>
                        </div>
                        {detail?.salary ? (
                          <div className='col-lg-3 col-sm-6'>
                            <i className='bi bi-currency-dollar'></i>
                            <span className='fs-6 mx-1'>{detail?.salary}</span>
                          </div>
                        ) : null}

                        <div className='col-lg-3 col-sm-6'>
                          <i className='bi bi-calendar'></i>
                          <span className='fs-6 mx-1'>{detail?.publishedAt}</span>
                        </div>
                      </div>
                    </div>
                    <h4>Experience : {detail?.experienceLevel}</h4>
                    {/* <h4 className='my-2 fs-7'>Skills :{detail?.skills.toString()}</h4> */}
                    {/* <h4 className='my-2 fs-7'>Industry :{detail?.industry}</h4> */}
                    <p className='my-2'>{detail?.jobSummary}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card mb-5'>
            <div className='card-body'>
              <div className='border border-1 border-secondary rounded-4 px-5 py-10'>
                <div className='row'>
                  <div className='col-12'>
                    <div dangerouslySetInnerHTML={{__html: detail?.content}} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='card'>
            <div className='card-body'>
              <div className='border border-muted border-2 rounded-4 py-10 px-5 my-5'>
                <div className='row align-items-center'>
                  <div className='col-lg-8 mb-5 col-sm-12 '>
                    <div className='row mb-5 align-items-center'>
                      <div className='col-lg-9 col-sm-12'>
                        <div className='mx-2'>
                          <h2 className=' m-0 '>{detail?.companyDetails?.companyName}</h2>
                          <span className='fs-6 text-muted'>
                            {detail?.companyDetails?.industry}
                            <span className='text-success'> Actively Hiring</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-12'>
                    <p>
                      {detail?.companyDetails?.about}

                      <a
                        href={detail?.companyDetails?.companyUrl}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <span className='text-primary'>see more</span>
                      </a>
                    </p>
                  </div>
                  {detail?.applied ? (
                    <></>
                  ) : (
                    <div className='col-lg-4 col-sm-12 text-lg-end my-2'>
                      <div
                        className='btn btn-primary w-100'
                        onClick={() => {
                          applied(detail?.dbId)
                        }}
                      >
                        Apply
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default JobDetails
