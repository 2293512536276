import { createSlice } from '@reduxjs/toolkit'
import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
    // variables
    loginSignUpVar: {
        isUserHasToken: getTokenInLocalStorage(),
        userLocalData: getUserDetailInLocalStorage()
    },
}

export const authSlice = createSlice({
    name: 'loginSignSlice',
    initialState,
    reducers: {
        setLoginSignUpVar: (state, action) => {
            state.loginSignUpVar = {
                ...state.loginSignUpVar,
                isUserHasToken: action.payload.token,
                userLocalData:action.payload
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setLoginSignUpVar
} = authSlice.actions
export default authSlice.reducer