import React from 'react'

export default function Documents() {
    return (
        <div className="card card-custom card-flush">
            <div className="card-body pb-0 ">
                <div className="row align-items-center justify-content-center" >
                    <div className="col-6 mb-5">
                        <h3>Select Documents</h3>
                    </div>
                    <div className="col-6 mb-5 text-end" >
                        <button className='btn btn-primary'>Upload</button>
                    </div>
                    <div className="col-12 mb-5 text-center" >
                        <div className="row ">
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                            <div className=" col-sm-4 col-xs-6 col-md-3">
                                <div className="form-check form-check-custom form-check-solid mb-5" style={{ color: "unset" }}>
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{ color: "unset" }} className="form-check-label" >
                                        Subject 2
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5" >
                        <h3 className="">Uploaded Documents</h3>
                    </div>
                    <div className="col-12 mb-5">
                        <div className="py-5 px-md-4 px-0 pt-0 overflow-auto">
                            <table className="table table-row-dashed table-row-gray-300 gy-7">
                                <thead>
                                    <tr className="fw-bolder fs-6  text-center">
                                        <th>
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label " style={{ color: "unset" }}>
                                                    Sr no
                                                </label>
                                            </div>
                                        </th>
                                        <th>Documents</th>
                                        <th>Date of Upload</th>
                                        <th>type</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='text-center '>
                                        <td>
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label " style={{ color: "unset" }}>
                                                    1
                                                </label>
                                            </div>
                                        </td>
                                        <td>Aadhar Card</td>
                                        <td>05/25/2020</td>
                                        <td>Soft Copy</td>
                                        <td><button className='btn btn-primary px-10 py-2'>Request</button></td>
                                    </tr>
                                    <tr className='text-center '>
                                        <td>
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label " style={{ color: "unset" }}>
                                                    2
                                                </label>
                                            </div>
                                        </td>
                                        <td>10th Report card</td>
                                        <td>05/25/2020</td>
                                        <td>Original</td>
                                        <td><button className='btn btn-primary px-10 py-2'>Request</button></td>
                                    </tr>
                                    <tr className='text-center '>
                                        <td>
                                            <div className="form-check form-check-custom form-check-solid">
                                                <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label className="form-check-label " style={{ color: "unset" }}>
                                                    3
                                                </label>
                                            </div>
                                        </td>
                                        <td>12th Report card</td>
                                        <td>05/25/2020</td>
                                        <td>Original</td>
                                        <td><button className='btn btn-primary px-10 py-2'>Request</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
