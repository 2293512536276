/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='fa-brands fa-elementor'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem to='/builder' icon='switch' title='Courses' fontIcon='bi-layers' /> */}
      {/* <div className='menu-item'> */}
      {/* <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div> */}
      {/* </div> */}
      {/* <SidebarMenuItemWithSub
        to='/courses'
        title='Courses'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/courses/exploreCourse' title='Explore Course' hasBullet={true} />
        <SidebarMenuItem to='/courses/collegeCourse' title='College Course' hasBullet={true} />
        <SidebarMenuItem to='/courses/myCourse' title='My Course' hasBullet={true} />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItem
        to='/assignment'
        icon='fa-solid fa-book'
        title='Assignment'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/events'
        title='Events'
        icon='fa-solid fa-calendar-days'
        fontIcon='bi-layers'
      />

      {/* <SidebarMenuItem
        to='/courses'
        icon='element-11'
        title='Courses'
        fontIcon='bi-app-indicator'
      /> */}

      <SidebarMenuItemWithSub
        to='/courses'
        title='Courses'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItem to='/courses/academic' title='Academic' hasBullet={true} />
        <SidebarMenuItem to='courses/ExploreCourse' title='Explore Course' hasBullet={true} />
        <SidebarMenuItem to='/courses/myCourse' title='My Course' hasBullet={true} />
      </SidebarMenuItemWithSub>

      {/* <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Events'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/accountInfo' title='Sports' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Annual Day' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Competitions' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Competitive Test' hasBullet={true} />
        <SidebarMenuItem
          to='/crafted/account/settings'
          title='Sessions by Expert'
          hasBullet={true}
        />
        <SidebarMenuItem to='/crafted/account/settings' title='Paid/Free Events' hasBullet={true} />
        <SidebarMenuItem
          to='/crafted/account/settings'
          title='3rd party events organised'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub> */}
      <SidebarMenuItem
        to='/assessments'
        icon='fa-solid fa-list-check'
        title='Assessments'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/achievements'
        icon='fa-solid fa-award'
        title='Achievements'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem
        to='/Attendance'
        icon='fa-solid fa-clipboard-user'
        title='Attendance'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItem to='/blog' title='Blog' icon='fa-solid fa-file-pen' fontIcon='bi-layers' />
      {/* <SidebarMenuItem to='/Jobs' icon='element-11' title='Jobs' fontIcon='bi-app-indicator' /> */}
      <SidebarMenuItem
        to='/Admission'
        icon='fa-solid fa-person-chalkboard'
        title='Admission'
        fontIcon='bi-app-indicator'
      />
      {/* 
      <SidebarMenuItem
        to='/Profile'             
        icon='element-11'
        title='Profile'
        fontIcon='bi-app-indicator'
      /> */}

      <SidebarMenuItem
        to='/job'
        icon='fa-solid fa-briefcase'
        title='Jobs'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/crafted/account/accountInfo'
        icon='fa-solid fa-gears'
        title='Setting'
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      {/* <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
