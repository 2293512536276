import React, { FC, useState } from 'react'
import { KTIcon } from '../../../helpers'

const Search: FC = () => {
  const [show, setShow] = useState(false)
  return (
    <>
     <div className="d-block d-md-none">
        <div className={`${show ? 'd-flex align-items-center position-relative' : ''}`}>
          <div onClick={() => { setShow(true) }} className={`${show ? "position-absolute" : ""}`}>
            <KTIcon iconName='magnifier' className={` s-3  ms-3 `} />
          </div>
          {
            show ? <input
              type='text'
              id='kt_filter_search'
              className='form-control form-control-white form-control-sm w-50px ps-9'
              placeholder='Search'
            /> : null
          }

        </div>
      </div>
      <div className="d-none d-md-block ">
        <div className='d-flex align-items-center position-relative '>
          <KTIcon iconName='magnifier' className='fs-3 position-absolute ms-3 ' />
          <input
            type='text'
            id='kt_filter_search'
            className='form-control form-control-white form-control-sm w-100px ps-9'
            placeholder='Search'
          />
        </div>
      </div>
     
    </>
  )
}

export { Search }
