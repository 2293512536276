/* eslint-disable jsx-a11y/anchor-is-valid */

type Props = {
  className: string
  item: any
}

const StatisticsCard = ({ className, item }: Props) => (
  <div className={`card card-flush text-center ${className}`}>
    <div className='card-header text-center align-items-center justify-content-center pt-5'>
      {
        !item ? <i className="fa-solid fa-circle-notch text-primary  fa-spin  p-10" style={{ fontSize: "30px", color: "unset" }}  ></i> : <div className='card-title d-flex flex-column align-items-center justify-content-center pt-5'>
          <i className={`ki-solid ki-${item?.title === "Stars Earned" ? "star" : item?.title === "Attendance" ? "user" : item?.title === "Assignments" ? "tablet-text-down" : item?.title === "Certificates" ? "award" : ""} fs-2x text-warning`}></i>
          <span className=' fw-bold text-dark me-2 lh-1 ls-n2 mt-3' style={{ fontSize: "23px" }}>{item?.value}</span>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>{item?.title}</span>
        </div>
      }
    </div>
  </div>
)
export { StatisticsCard }
