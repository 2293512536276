import { createSlice } from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
    // variables
    details: [],
}

export const UserDetailSlice = createSlice({
    name: 'UserDetailSlice',
    initialState,
    reducers: {
        setDetails: (state, action) => {
            state.details = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setDetails,
} = UserDetailSlice.actions
export default UserDetailSlice.reducer