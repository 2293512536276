/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLazyEventQuery } from '../../../Api/allApi'
import moment from 'moment'

type Props = {
  className?: string
}

const ScheduleCalender: React.FC<Props> = ({ className }) => {
  const [currentDate]: any = useState([])

  // event api
  const [eventTrigger, eventResult] = useLazyEventQuery();
  const { isSuccess, isFetching } = eventResult
  useEffect(() => {
    eventTrigger({});
    let curr = new Date()
    for (let i = 1; i <= 11; i++) {
      let first: any = curr.getDate() - curr.getDay() + i
      let day: any = new Date(curr.setDate(first)).toISOString().slice(0, 10)
      currentDate.push(day)
    }
  }, [eventTrigger, currentDate])

  const [eventData, setEventData]: any = useState([]);
  // call when api calling done successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setEventData(eventResult?.data);
    }
  }, [isSuccess, isFetching, eventResult])

  function apiCall(date: any) {
    eventTrigger({ date: date });
  }
  return (
    <div className='col-lg-12 col-xl-12 mb-5 mb-xl-0'>
      <div className='card h-md-100'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder text-dark'>What’s up Today</span>
            <span className='text-muted mt-1 fw-bold fs-7'>{eventData?.subTitle}</span>
          </h3>
          {/* <div className='card-toolbar'>
            <a href='#Report' className='btn btn-sm btn-light'>
              Report Center
            </a>
          </div> */}
        </div>
        <div className='card-body pt-7 px-0'>
          <ul className='nav nav-stretch nav-pills nav-pills-custom nav-pills-active-custom d-flex justify-content-between mb-8 px-5'>
            {currentDate.map((date: any, index: any) => {
              const isActive = index === 0
              return (
                <li className='nav-item p-0 ms-0' key={`cal ${index.toString()}`}>
                  <a
                    className={`nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px py-4 px-3 btn-active-danger ${isActive && 'active'}`}
                    data-bs-toggle='tab'
                    href={`#kt_timeline_widget_3_tab_content_${index + 1}`}
                    onClick={() => { apiCall(date) }}
                  >
                    <span className='fs-7 fw-bold'>{moment(date).format('dd')}</span>
                    <span className='fs-6 fw-bolder'>{moment(date).format('DD')}</span>
                  </a>
                </li>
              )
            })}
          </ul>
          <div className='tab-content mb-2 px-9'>
            {currentDate.map((schedule: any, index: any) => {
              const isActive = index === 0
              const id = `kt_timeline_widget_3_tab_content_${index + 1}`
              return (
                <div
                  className={`tab-pane fade  ${isActive && 'show active'}`}
                  id={id}
                  key={index.toString()}
                >
                  {
                    eventData?.length === 0 ? <div className='text-center'>
                      <i
                        className='fa-solid fa-circle-notch text-center fa-spin m-auto p-10 text-primary'
                        style={{ fontSize: '30px', color: 'unset' }}
                      ></i>
                    </div> :
                      eventData?.events?.map((data: any,items) => {
                        return (
                          <div className='d-flex align-items-center mb-6' key={items.toString()}>
                            <span
                              data-kt-element='bullet'
                              className={`bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 `}
                              style={{ backgroundColor: data?.stripColor }}
                            ></span>
                            <div className='flex-grow-1 me-5'>
                              <div className='text-gray-800 fw-bold fs-2'>
                                {data?.timePeriod}
                              </div>
                              <div className='text-gray-700 fw-bold fs-6'>{data?.eventTitle}</div>
                              <div className='text-gray-400 fw-bold fs-7' style={{ cursor: "pointer" }}>
                                <a href={data?.link} className='text-primary opacity-75-hover fw-bold'>
                                  {data?.eventShortDescr}
                                </a>
                              </div>
                            </div>
                            <a
                              href={data?.link}
                              target='_blank'
                              rel='noreferrer'
                              className='btn btn-sm btn-light'
                            >
                              View
                            </a>
                          </div>
                        )
                      })
                  }
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export { ScheduleCalender }
