/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import './testimonial.css'
type Props = {
  className: string,
  data: any
}

const TablesWidget5: React.FC<Props> = ({ className, data }) => {
  return (
    <div className={`card text-center  p-0 ${className} `} style={{ width:"90%",margin:"auto" }}>
      {/* begin::Body */}
      {!data ? <div className='card-body  text-center'><i className="fa-solid fa-circle-notch text-primary fa-spin  " style={{ fontSize: "30px", color: "unset" }}  ></i> </div>
        :
        <div className='card-body  p-0 d-flex align-items-center justify-content-center flex-column'>
          <a href={data?.link} target='_blank' rel='noreferrer' style={{ cursor: "pointer",height:"100%",width:"100%" }}>
            <img src={data?.imgLink} style={{height:"100%",width:"100%"}} alt="table"/>
          </a>
        </div>
      }
      {/* end::Body */}
    </div>
  )
}

export { TablesWidget5 }
