import {createSlice} from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
  // variables
  allAssignment: [],
  dueAssignment: [],
  submitAssignment: [],
  gradedAssignment: [],
  rejectAssignment: [],
  filter: [],
}

export const assignmentSlice = createSlice({
  name: 'assignmentSlice',
  initialState,
  reducers: {
    setAllAssignment: (state, action) => {
      state.allAssignment = action.payload
    },
    setDueAssignment: (state, action) => {
      state.dueAssignment = action.payload
    },
    setSubmitAssignment: (state, action) => {
      state.submitAssignment = action.payload
    },
    setGradedAssignment: (state, action) => {
      state.gradedAssignment = action.payload
    },
    setRejectedAssignment: (state, action) => {
      state.rejectAssignment = action.payload
    },
    setFilter:(state, action) => {
        state.filter = action.payload
      },
  },
})

// Action creators are generated for each case reducer function
export const {
  setAllAssignment,
  setDueAssignment,
  setSubmitAssignment,
  setGradedAssignment,
  setRejectedAssignment,
  setFilter
} = assignmentSlice.actions
export default assignmentSlice.reducer
