/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'

type Props = {
  className: string
}

const ListsWidget3: React.FC<Props> = ({className}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='main-card-header border-0 text-center'>
        <h3 className='card-title fw-bold text-dark text-center mt-4'> Schedule</h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-2'>
        <div className='calender d-flex justify-content-between align-items-center'>
        <div>Fr
          <p>20</p></div>
      <div>
           Sa
          <p>21</p>
      </div>
      <div>Su
        <p>22</p>
      </div>
        <div className='active'>
        Mo
        <p>23</p>
        </div>
        <div>
        Tu
        <p>24</p>
        </div>
        <div>
        We
        <p>25</p>
        </div>
        <div>
        Th
        <p>26</p>
        </div>
        <div>
        Fr
        <p>27</p>
        </div>
        <div>
        Sa
        <p>28</p>
        </div>
        <div>
        Su
        <p>29</p>
        </div>
        <div>
        Mo
        <p>30</p>
        </div>
        </div>
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-60px bg-primary'></span>
          {/* end::Bullet */}
         
          {/* begin::Description */}
          <div className='flex-grow-1 ms-2 mt-2'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
            10:20 - 11:00 AM
            </a>
            <span className='text-muted fw-semibold d-block'>9 Degree Project Estimation Meeting</span>
            <p>Lead by <span className='text-primary'>Peter Marcus</span></p>
          </div>
          {/* end::Description */}
          <button style={{background:"#ccc", border:"none",padding:'5px'}}>View</button>
        </div>
        {/* end:Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-60px bg-warning'></span>
          {/* end::Bullet */}
          {/* begin::Description */}
          <div className='flex-grow-1 ms-2 mt-2'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
            16:30 - 17:00 PM
            </a>
            <span className='text-muted fw-semibold d-block'>Dashboard UI/UX Design Review</span>
            <p>Lead by <span className='text-primary'>Lead by Bob</span></p>
          </div>
          {/* end::Description */}
          <button style={{background:"#ccc", border:"none",padding:'5px'}}>View</button>
         
        </div>
        {/* end:Item */}
        {/* begin::Item */}
        <div className='d-flex align-items-center mb-8'>
          {/* begin::Bullet */}
          <span className='bullet bullet-vertical h-60px bg-success'></span>
          {/* end::Bullet */}
         
          {/* begin::Description */}
          <div className='flex-grow-1 ms-2 mt-2'>
            <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
            12:00 - 13:40 AM
            </a>
            <span className='text-muted fw-semibold d-block'>Marketing Campaign Discussion</span>
            <p>Lead by <span className='text-primary'> Lead by Mark Morris</span></p>
          </div>
          {/* end::Description */}
         <button style={{background:"#ccc", border:"none",padding:'5px'}}>View</button>
        </div>
        {/* end:Item */}
       
        
        
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidget3}
