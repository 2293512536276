import React, {useEffect, useState} from 'react'
import Filter from './Components/Filter'
import JDetails from './Components/JDetails'
import {useJobsDetailsMutation, useLazyJobFilterQuery} from '../../Api/allApi'
import {useLocation} from 'react-router-dom'
const JobCard = () => {
  const location = useLocation()
  const [isOpen, setIsopen] = useState(false)
  function openNav() {
    setIsopen(true)
  }
  function closeNav() {
    setIsopen(false)
  }
  const ToggleSidebar = () => {
    isOpen === true ? setIsopen(false) : setIsopen(true)
  }
  const [jobsTrigger, result] = useJobsDetailsMutation()
  const {isSuccess, isFetching} = result
  const [jobsFilter, FResult] = useLazyJobFilterQuery()
  const {isSuccess: isFSuccess, isFetching: isFFEtching} = FResult
  const [filterData, setFilterData] = useState([])
  const [jobDetail, setJobDetails] = useState([])
  useEffect(() => {
    jobsFilter()
  }, [])
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setJobDetails(result?.data?.data)
    }
  }, [isSuccess, isFetching])
  useEffect(() => {
    if (isFSuccess && !isFFEtching) {
      setFilterData(FResult?.data)
      let payload = {
        jobStatus:
          location.pathname === `/job/view`
            ? 'view'
            : location.pathname === `/job/applied`
            ? 'applied'
            : location.pathname === `/job/shortlist`
            ? 'shortlist'
            : location.pathname === `/job/rejected`
            ? 'rejected'
            : 'onhold',
        contractType: FResult?.data?.filters[0]?.options,
        experienceLevel: FResult?.data?.filters[1]?.options,
        location: FResult?.data?.filters[2]?.options,
      }
      jobsTrigger(payload)
    }
  }, [isFFEtching, isFSuccess])

  const [data, setData] = useState([])
  useEffect(() => {
    if (jobDetail?.length !== 0) {
      let arr = []
      jobDetail?.map((item) => {
        if (location.pathname === `/job/${item?.jobStatus}`) {
          arr.push(item)
        }
        if (location?.pathname === `/job/view`) {
          arr.push(item)
        }
        return item
      })
      setData(arr)
    }
  }, [jobDetail, location])
  return (
    <>
      <div className='card card-custom'>
        <div className='card-body'>
          {/* <div className='d-flex align-items-center'>
            <h4 className='mx-2'>Trending Skills:</h4>
            <div className='row'>
              <div className='col-lg-12'>
                <div className='my-5'>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-box-fill text-light-primary p-2 bg-primary rounded-4'></i>
                      <span className='mx-2'>Photoshop</span>
                    </div>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-box-fill text-light-primary p-2 bg-primary rounded-4'></i>
                      <span className='mx-2'>Indesign</span>
                    </div>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-box-fill text-light-primary p-2 bg-primary rounded-4'></i>
                      <span className='mx-2'>Adobe XD</span>
                    </div>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-box-fill text-light-primary p-2 bg-primary rounded-4'></i>
                      <span className='mx-2'>Figma</span>
                    </div>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-box-fill text-light-primary p-2 bg-primary rounded-4'></i>
                      <span className='mx-2'>Invision</span>
                    </div>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <div className='d-flex align-items-center'>
                      <i className='bi bi-box-fill text-light-primary p-2 bg-primary rounded-4'></i>
                      <span className='mx-2'>Illustrator</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='border border-secondary border-dashed mb-3'></div> */}

          {/* <div className='row'>
            <div className='col-lg-12'>
              <div className='d-flex align-items-center'>
                <h4 className='mx-2'>Trending Posts : </h4>
                <div className='posts'>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <span className='mx-2'>UI /Ux Designer</span>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <span className='mx-2'>Graphic Designer</span>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <span className='mx-2'>Product Manager</span>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <span className='mx-2'>Fresher</span>
                  </div>
                  <div className='btn btn-outline btn-outline-solid  btn-outline-primary btn-active-light-primary px-4 py-2 mx-3 my-3'>
                    <span className='mx-2'>Lead UX Designer</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div className='border border-secondary border-dashed mt-3 mb-3'></div> */}
          <div className='row align-items-center justify-content-end'>
            <div className='col-3 mb-5 d-block d-lg-none  text-end'>
              <div
                className='btn-primary btn '
                onClick={() => {
                  openNav()
                }}
              >
                Filter
              </div>
            </div>
          </div>
          <div className='row  justify-content-between'>
            <div className='col-lg-9'>
              {data &&
                data?.map((item) => {
                  return <JDetails item={item} />
                })}
            </div>
            <div className='col-lg-3 d-none  d-lg-block'>
              <div className='border border-1 border-secondary rounded-4 my-5'>
                <div className='row justify-content-around'>
                  <div className='col-lg-12'>
                    <Filter active='static-filter' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Filter
        setIsopen={setIsopen}
        closeNav={closeNav}
        active={isOpen ? 'filter-active nav_sidenav' : ''}
      />
      <div
        className={`sidebar-overlay ${isOpen === true ? 'active  position-fixed ' : ''}`}
        onClick={ToggleSidebar}
      ></div>
    </>
  )
}

export default JobCard
        