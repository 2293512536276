import React, {useEffect, useState} from 'react'
import '../css/job.css'
import {useLazyJobFilterQuery} from '../../../Api/allApi'
import Select from 'react-select'
const Filter = (props) => {
  const [jobsFilter, result] = useLazyJobFilterQuery()
  const {isSuccess, isFetching} = result
  useEffect(() => {
    jobsFilter()
  }, [jobsFilter])
  const [filterData, setFilterData] = useState([])
  const [batch, setBatch] = useState()
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setFilterData(result?.data)
      let arr = []
      result?.data?.filters?.map((item) => {
        if (item?.filterType === 'mdropdown') {
          item?.options?.map((option) => {
            arr.push({value: option, label: option})
          })
        }
      })
      setBatch(arr)
    }
  }, [isFetching, isSuccess, result])

  const {active} = props
  return (
    <div className='job-filter p-5'>
      <div
        className={`container-fluid mt-0 nav_sidenav ${active}  p-2 px-5 card position-fixed `}
        style={{zIndex: '999'}}
        id='mySidenav'
      >
        <div className='row align-items-center justify-content-between'>
          <div className='col-9 mb-3'>
            <h2>Filters </h2>
          </div>
          {filterData?.filters?.map((item) => {
            return (
              <>
                <div className='col-12 mb-3'>
                  <h3>{item?.name}</h3>
                </div>
                <div className='col-12 mb-3'>
                  {item?.filterType === 'checkbox' ? (
                    <ul>
                      {item?.options?.map((option, index) => {
                        return (
                          <li>
                            <div className='sd-link'>
                              <div className='form-check form-check-custom form-check-solid'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  value=''
                                  id={option + index}
                                />
                                <label
                                  className='form-check-label'
                                  for={option + index}
                                  style={{color: 'unset'}}
                                >
                                  {option}
                                </label>
                              </div>
                            </div>
                          </li>
                        )
                      })}
                    </ul>
                  ) : (
                    <Select
                      options={batch}
                      isSearchable={true}
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          height: '100%',
                          width: '100%',
                          boxShadow: 'unset',
                        }),
                        option: (base) => ({
                          ...base,
                          cursor: 'pointer',
                          margin: '0px',
                          boxShadow: 'unset',
                          color: '#7e8299',
                          fontWeight: 'bold',
                          background: 'white', // this was the mistake (I needed to remove this)
                          ':hover': {
                            color: '#009ef7',
                            backgroundColor: '#f1faff',
                          },
                        }),
                      }}
                      placeholder='search and select city'
                    />
                  )}
                </div>
              </>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Filter
