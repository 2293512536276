/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  ListsWidget2,
  ListsWidget4,
  TablesWidget5,
  ChartsWidget1,
  StatisticsCard,
} from '../../../_metronic/partials/widgets'
import {ScheduleCalender} from './components/ScheduleCalender'
import {useLazyDashboardQuery} from '../../Api/allApi'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import {Carousel} from 'react-responsive-carousel'

const DashboardWrapper = () => {
  // dashboard api
  const [dashboardTrigger, result] = useLazyDashboardQuery()
  const {isSuccess, isFetching} = result
  const [SliderItem, setSliderItem] = useState(3)
  useEffect(() => {
    dashboardTrigger({})
    if (window.innerWidth <= 500) {
      setSliderItem(1)
    } else {
      setSliderItem(3)
    }
  }, [dashboardTrigger])
  useEffect(() => {
    const handleWindowResize = () => {
      if (window.innerWidth <= 500) {
        setSliderItem(1)
      } else {
        setSliderItem(3)
      }
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // for data
  const [dashboardData, setDashboardData] = useState({})
  const [alerts, setAlerts] = useState([])
  // when api is fetching data successfully
  useEffect(() => {
    if (isSuccess && !isFetching) {
      setDashboardData(result?.data)
      if (result?.data?.alerts) {
        let data = result?.data?.alerts
        var hidden = localStorage.getItem('alerts')
        var hidAlert = JSON.parse(hidden)
        var abc = []
        if (hidAlert) {
          data?.map((element) => {
            if (hidAlert.includes(element?.message) === false) {
              abc.push(element)
            }
            return element
          })
          setAlerts(abc)
        } else {
          setAlerts(data)
        }
      }
    }
  }, [isSuccess, isFetching, result?.data])
  const intl = useIntl()
  const [currentIndex, setSlide] = useState(0)

  function hideAlert(data) {
    var students = []
    var stored = JSON.parse(localStorage.getItem('alerts'))
    if (stored) {
      students.push(...stored)
    }
    students.push(data)
    localStorage.setItem('alerts', JSON.stringify(students))
    setAlerts((current) => current.filter((item) => item?.message !== data))
  }
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        {dashboardData?.banner ? (
          <div className='col-12'>
            <div
              className='bg-light-dark  '
              style={{borderRadius: '30px', border: 'solid 2px #BB6166'}}
            >
              <div className='d-flex'>
                <div
                  className='px-5 py-3 text-white'
                  style={{borderRadius: '30px', backgroundColor: '#BB6166'}}
                >
                  Notice
                </div>
                <marquee behavior='scroll' className='px-5 py-3' direction='left'>
                  {dashboardData?.banner}
                </marquee>
              </div>
            </div>
          </div>
        ) : null}
        {alerts.length !== 0 ? (
          <div className='col-12'>
            {alerts === 0 ? (
              <div className='card p-4 pb-0' style={{height: '300px'}}>
                <i
                  className='fa-solid fa-circle-notch fa-spin  text-primary '
                  style={{fontSize: '30px', color: 'unset'}}
                ></i>
              </div>
            ) : (
              <div
                className='card p-4 pb-0'
                style={{height: 'auto', maxHeight: '300px', overflowX: 'auto'}}
              >
                {alerts?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`alert alert-${item?.type === 'info' ? 'primary' : item?.type}`}
                      role='alert'
                    >
                      <div className='d-flex align-items-center justify-content-between'>
                        {item?.message}
                        <h4
                          style={{cursor: 'pointer'}}
                          onClick={() => {
                            hideAlert(item?.message)
                          }}
                        >
                          X
                        </h4>
                      </div>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        ) : null}

        <div className='col-xxl-8'>
          <div className='row align-items-center'>
            {!dashboardData?.kpis ? (
              <div className='col-12 text-center card'>
                <i
                  className='fa-solid fa-circle-notch  fa-spin  p-10 text-primary'
                  style={{fontSize: '30px', color: 'unset'}}
                ></i>
              </div>
            ) : (
              dashboardData?.kpis?.map((item) => {
                return (
                  <div className='col-md-3 mb-5 col-6 text-center'>
                    <StatisticsCard className='' item={item} />
                  </div>
                )
              })
            )}
          </div>
          <div className='mb-8 mt-8'>
            {/* <ListsWidget3 className='card-xxl-stretch' /> */}
            <ScheduleCalender />
          </div>
          {/* </div> */}

          <div className='row g-5 gx-xxl-8'>
            {dashboardData?.attendance ? (
              <div className='col-12'>
                <ChartsWidget1 className='card-xxl-stretch' data={dashboardData?.attendance} />
              </div>
            ) : null}
          </div>
        </div>

        {/* begin::Col */}
        <div className='col-xxl-4'>
          <div className='g-5 g-xl-8'>
            <div className=''>
              <ListsWidget2 className='card-xl-stretch mb-8' data={dashboardData?.feeds} />
            </div>

            <div className=''>
              <ListsWidget4
                className='card-xl-stretch mb-5 mb-8'
                data={dashboardData?.assignment}
              />
            </div>
          </div>
        </div>
        <div className='col-xxl-8'>
          <div className=' mb-5 flex-column-fluid '>
            <Carousel
              centerMode
              infiniteLoop={false}
              centerSlidePercentage={100 / SliderItem}
              selectedItem={currentIndex}
              autoPlay={false}
              autoFocus={true}
              margin={10}
              showArrows={true}
              onChange={(index) => {
                if (index === dashboardData?.advertisement - 2) {
                  setSlide(0)
                } else {
                  setSlide(index)
                }
              }}
              showIndicators={false}
              showStatus={false}
            >
              {dashboardData?.advertisement?.map((x) => {
                return (
                  <>
                    <TablesWidget5 className='' data={x} />
                  </>
                )
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </>
  )
}

export {DashboardWrapper}
