import React from 'react'

export default function FessStructure() {
    return (
        <div className="card card-custom card-flush">
            <div className="card-header">
                <h3 className="card-title" style={{ width: "100%", display: "unset" }}>
                    <div className="row align-items-center justify-content-center">
                        <div className="col-7">
                            <div className="p-md-5 p-0">
                                <div className="border border-3 border-primary rounded px-md-4 py-md-2 p-2" style={{ width: "fit-content" }}>
                                    <h4>Total Amount</h4>
                                    <p>$ 1,50,0000</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 text-end">
                            <div className="px-md-4 px-0">
                                <h5 className='badge badge-light-danger'>Previous Payments</h5>
                            </div>
                        </div>
                    </div>
                </h3>
            </div>
            <div className="card-body py-5 pt-0">
                <div className="py-5 px-md-4 px-0 pt-0 overflow-auto">
                    <table className="table table-row-dashed table-row-gray-300 gy-7">
                        <thead>
                            <tr className="fw-bolder fs-6  text-center">
                                <th>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label " style={{ color: "unset" }}>
                                            Sr no
                                        </label>
                                    </div>
                                </th>
                                <th>Particulars</th>
                                <th>Date of Payment</th>
                                <th>Payment Mode</th>
                                <th>Total</th>
                                <th>Status</th>
                                <th>Receipt</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className='text-center'>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label className="form-check-label " style={{ color: "unset" }}>
                                            1
                                        </label>
                                    </div>
                                </td>
                                <td>Admission Fess</td>
                                <td>05/25/2020</td>
                                <td>Cheque</td>
                                <td>Rs. 50,000</td>
                                <td>
                                    <span className="badge badge-success">Paid</span>
                                </td>
                                <td><i className="fa-solid fa-download"></i></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="card-footer pt-0 text-end">
                <button className='btn btn-primary px-3 py-2'>Make a Payment</button>
            </div>
        </div>
    )
}
