import React from 'react'

export default function Order() {
    return (
        <div className="card card-custom card-flush">
            <div className="card-body pb-0 ">
                <div className="row  align-items-center justify-content-center">
                    <div className="col-12 mb-5">
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" >
                                <h3>Uniform</h3>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 mb-5" >
                        <div className="row  align-items-center justify-content-center">
                            <div className="col-md-2  col-sm-12 text-start" style={{ width: "fit-content" }}  >
                                <label className="form-label min-w-100px me-3">Gender</label>
                            </div>
                            <div className="col-md-10 col-sm-12 text-start">
                                <div className="d-flex">
                                    <div className="form-check form-check-custom form-check-solid me-5">
                                        <input className="form-check-input" type="radio" value="" disabled id="flexRadioDisabled" />
                                        <label className="form-check-label" style={{ color: "unset" }}>
                                            Male
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid">
                                        <input className="form-check-input" type="radio" value="" disabled id="flexRadioDisabled" />
                                        <label className="form-check-label" style={{ color: "unset" }}>
                                            Female
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5" >
                        <div className="row  align-items-center justify-content-center">
                            <div className="col-2" style={{ width: "fit-content" }}>
                                <label className="form-label min-w-100px me-3">Size</label>

                            </div>
                            <div className="col-10 text-start">
                                <select className="form-select" aria-label="Select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5" >
                        <div className="row  align-items-center justify-content-center">
                            <div className="col-2" style={{ width: "fit-content" }}>
                                <label className="form-label min-w-100px me-3">Quantity</label>

                            </div>
                            <div className="col-10 text-start ">
                                <select className="form-select" aria-label="Select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5">
                        <div className="form-check form-check-custom form-check-solid">
                            <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                            <label className="form-check-label" >
                                <h3>NoteBook</h3>
                            </label>
                        </div>
                    </div>
                    <div className="col-12 mb-5" >
                        <div className="row  align-items-center justify-content-center">
                            <div className="col-md-2  col-sm-12 text-start" style={{ width: "fit-content" }}  >
                                <label className="form-label min-w-100px me-3">Semester</label>
                            </div>
                            <div className="col-md-10 col-sm-12 text-start">
                                <select className="form-select" aria-label="Select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5" >
                        <div className="row  align-items-center justify-content-center">
                            <div className="col-2" style={{ width: "fit-content" }}>
                                <label className="form-label min-w-100px me-3">Size</label>

                            </div>
                            <div className="col-10 text-start">
                                <select className="form-select" aria-label="Select example">
                                    <option>Open this select menu</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mb-5" >
                        <div className="row justify-content-center">
                            <div className="col-2 mb-5" style={{ width: "fit-content" }}>
                                <label className="form-label min-w-100px me-3">Subject</label>

                            </div>
                            <div className="col-10 text-start mb-5">
                                <div className="form-check form-check-custom form-check-solid">
                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    <label style={{color:"unset"}} className="form-check-label" >
                                        All
                                    </label>
                                </div>
                                <div className="d-flex flex-wrap align-items-center justify-content-between mt-5" style={{width:"400px"}}>
                                    <div className="form-check form-check-custom form-check-solid mb-5" style={{color:"unset"}}>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label style={{color:"unset"}} className="form-check-label" >
                                         Subject 2
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid mb-5" style={{color:"unset"}}>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label style={{color:"unset"}} className="form-check-label" >
                                         Subject 2
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid mb-5" style={{color:"unset"}}>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label style={{color:"unset"}} className="form-check-label" >
                                         Subject 2
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid mb-5" style={{color:"unset"}}>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label style={{color:"unset"}} className="form-check-label" >
                                         Subject 2
                                        </label>
                                    </div>
                                    <div className="form-check form-check-custom form-check-solid mb-5" style={{color:"unset"}}>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                        <label style={{color:"unset"}} className="form-check-label" >
                                         Subject 2
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-footer pt-0 text-end">
                <button className='btn btn-primary px-4 py-2'>Request</button>
            </div>
        </div>
    )
}
