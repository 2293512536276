import React from 'react'
import {Link, useLocation} from 'react-router-dom'

type Props = {
  className?: string
}

const JobHeader: React.FC<Props> = ({className}) => {
  const location = useLocation()

  return (
    <div className={`card ${className} mb-6`}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        <div className="card-title align-items-start flex-column">
          <span className='fw-bold fs-3 mb-1'>Jobs</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Check Jobs status</span>
        </div>
      </div>
      {/* end::Header */}
      <div className='card-body pb-0 pt-0'>
        <div className='d-flex overflow-auto h-55px'>
          <ul className='nav nav-stretch justify-content-start p-2 nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/job/view' && 'active')
                }
                to='/job/view'
              >
                View
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/job/applied' && 'active ')
                }
                to='/job/applied'
              >
                Applied
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/job/shortlist' && 'active ')
                }
                to='/job/shortlist'
              >
                Short Listed
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/job/rejected' && 'active ')
                }
                to='/job/rejected'
              >
                Rejected
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/job/onhold' && 'active ')
                }
                to='/job/onhold'
              >
                On Hold
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {JobHeader}
