import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function AdmissionHeader() {
  const location = useLocation()
    return (
        <div>
            <div className={`card  mb-6`}>
                {/* begin::Header */}
                <div className='card-header pt-5' style={{display:"unset"}}>
                    <h3 className='card-title align-items-start justify-content-between flex-wrap' style={{width:"100%"}}>
                        <span className='card-label fw-bold fs-3 mb-1'>Admission</span>
                        <h4 className='text-danger'>Enrollment id : #65432</h4>
                    </h3>
                </div>
                {/* end::Header */}
                <div className='card-body pb-0 pt-0'>
                    <div className='d-flex overflow-auto h-55px'>
                        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/fess' && 'active')
                                    }
                                    to='/Admission/fess'
                                >
                                    Fess Structure
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/stream' && 'active')
                                    }
                                    to='/Admission/stream'
                                >
                                    Change Stream
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/order' && 'active')
                                    }
                                    to='/Admission/order'
                                >
                                    Orders
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link
                                    className={
                                        `nav-link text-active-primary me-6 ` +
                                        (location.pathname === '/Admission/documents' && 'active')
                                    }
                                    to='/Admission/documents'
                                >
                                    Documents
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
