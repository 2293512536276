/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { assignmentTableHeader, assignmentTableBody } from '../../../helpers'
import { map, uniq } from 'lodash';

type Props = {
  className: string
}

const subjectList = uniq(map(assignmentTableBody, 'subject'))

const AssignmentTables: React.FC<Props> = ({ className }) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Assignment</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>Check your assignment status</span>
        </h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <KTIcon iconName='category' className='fs-2' />
          </button> */}
          <div>
            <button
              type='button'
              className='btn btn-sm btn-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              {/* <KTIcon iconName='plus' className='fs-2' /> */}
              <i className='fas fa-circle fs-1x'></i>
              Status
            </button>
            {/* begin::Menu 2 */}
          </div>

          <div>
            <button
              type='button'
              className='btn btn-sm btn-light-primary ms-4'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              {/* <FontAwesomeIcon icon="fa-sharp fa-solid fa-filter" /> */}
              <i className='fas fa-filter fs-1x'></i>
              {/* <KTIcon iconName='filter' className='fs-2' /> */}
              Subject
            </button>

            {/* begin::Menu 2 */}
            <div
              className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-150px pb-3'
              data-kt-menu='true'
            >
              {/* begin::Menu item */}
              <div className='menu-item px-3'>
                <div className='menu-content fs-6 text-dark fw-bold px-3 py-4'>Subject Filter</div>
              </div>
              {/* end::Menu item */}
              {/* begin::Menu separator */}
              <div className='separator mb-3 opacity-75'></div>
              {/* end::Menu separator */}
              {/* begin::Menu item */}
              {subjectList.map((item, index) => {
                return (
                  <div className='menu-item px-3' key={index.toString()}>
                    <div className='menu-link px-3'>{item}</div>
                  </div>
                )
              })}
            </div>
            {/* end::Menu 2 */}
          </div>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted bg-light'>
                {assignmentTableHeader.map((item, index) => {
                  const firstElementStyle = index === 0 ? `ps-4 rounded-start` : ''

                  return (
                    <th
                      className={`ps-4 min-w-200px ${firstElementStyle}`}
                      key={item.id.toString()}
                    >
                      {item?.label}
                    </th>
                  )
                })}
                <th className='min-w-200px text-end rounded-end'></th>
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
              {assignmentTableBody.map((item, index) => {
                return (
                  <tr>
                    <td className='ps-4 rounded-start'>
                      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item.subject}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item.assignment_no}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item.chapter}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item.date}
                      </span>
                    </td>
                    <td>
                      <span className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                        {item.lecturer}
                      </span>
                    </td>
                    <td className='text-end rounded-end'>
                      <a href='#' className='btn btn-warning'>
                        Upload
                      </a>
                    </td>
                  </tr>
                )
              })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { AssignmentTables }
