import React from 'react'

export default function Stream() {
    return (
        <div className="card card-custom card-flush">
            <div className="card-body  ">
                <div className="row justify-content-start">
                    <div className="col-12 mb-5">
                        <table cellPadding="10px" className='w-100'>
                            <tr>
                                <td className='text-start w-200px'>
                                    <div className="">
                                        <label className="form-label">Current Stream</label>
                                    </div>
                                </td>
                                <td>
                                    <div className="text-start w-100">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter your Current Stream"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-start w-200px'>
                                    <label className="form-label">Field Change</label>
                                </td>
                                <td>
                                    <div className="w-100">
                                        <select className="form-select" aria-label="Select example">
                                            <option>Open this select menu</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td className='text-start w-200px'>

                                    <label className="form-label min-w-100px me-2">Reason For Change</label>
                                </td>
                                <td>
                                    <div className="w-100">
                                        <div className="form-group">
                                            <textarea className="form-control" id="exampleFormControlTextarea1" rows={3}></textarea>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>

                </div>
            </div>
            <div className="card-footer pt-0 text-end">
                <button className='btn btn-primary px-4 py-2'>Request</button>
            </div>
        </div>
    )
}
