/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useNavigate } from 'react-router'

type Props = {
  className: string
  data: any
}

const ListsWidget4: React.FC<Props> = ({ className, data }) => {
  const navigate = useNavigate()
  function redirect() {
    navigate('/assignment/all')
  }
  return (
    <>
      {/* begin::Header */}
      <div className='card card-xl-stretch mb-xl-8'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-center'>
            <img src={require("../../../assets/assigment.png")} alt='assignment' />
            <span className='card-label fw-bold text-dark'>Assignment</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <button
              type='button'
              className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() => { redirect() }}
            >
              <img src={require('../../../assets/export.png')} alt='expore' />
            </button>
            {/* <Dropdown1 /> */}
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        {!data ? <div className='card-body pt-5 text-center'><i className="fa-solid fa-circle-notch text-primary  fa-spin  p-10" style={{fontSize:"30px",color:"unset"}}  ></i> </div> :
          <div className='card-body pt-5'>
            {/* begin::Item */}
            {
              data?.map((item: any,index:any) => {
                return (
                  <div className='d-flex align-items-sm-center mb-7' key={index.toString()}>
                    {/* begin::Symbol */}
                    <div className='symbol symbol-50px me-5'>
                      <span className='symbol-label'>
                        <img src={item?.iconLink} alt='icon' />
                      </span>
                    </div>
                    {/* end::Symbol */}
                    {/* begin::Section */}
                    <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                      <div className='flex-grow-1 me-2'>
                        <a href='#' className='text-gray-800 text-hover-primary fs-7 fw-bold'>
                          {item?.subjectName} - {item?.assignmentName}
                        </a>
                        <span className='text-muted fw-semibold d-block fs-7'>Due: {item?.dueDate}</span>
                      </div>
                    </div>
                    <span className={`badge badge-light-${item?.status === "approved" ? "primary" : item?.status === "rejected" ? "danger" : item?.status === "pending" ? "warning" : "success"}`}>{item?.status}</span>
                    {/* end::Section */}
                  </div>
                )
              })
            }
            {/* end::Item */}
          </div>
        }
        {/* end::Body */}
      </div>
    </>
  )
}

export { ListsWidget4 }
