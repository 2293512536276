import React, {useEffect, useState} from 'react'
import './css/notification.css'
import {
  useLazyAttendanceSubjectQuery,
  useLazyCourseFilterQuery,
  useLazyCourseQuery,
  useLazyFeedsQuery,
} from '../../Api/allApi'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {setCourse, setFilter} from '../../redux/CourseSlice'

export function Filter(props) {
  const {active, ToggleSidebar} = props
  const dispatch = useDispatch()
  // filter api
  const [filter, result] = useLazyCourseFilterQuery()
  const {isSuccess, isFetching} = result
  // course api
  const [CourseTrigger, CourseResult] = useLazyCourseQuery()
  const {isSuccess: isCSuccess, isFetching: isCFetching} = CourseResult
  useEffect(() => {
    filter({course_type: 'collage'})
  }, [filter])
  const [feedsData, setFeedsData] = useState([])
  const [sortBy, setSortBy] = useState([])
  const [filteredValue, setFilteredValue] = useState({})
  const [sortValue, setSortValue] = useState('')
  const [check, setChecked] = useState({})
  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(setFilter(result?.data))
      setFeedsData(result?.data?.filters)
      setSortBy(result?.data?.sortBy)
      let arr = {}
      let checked = {}
      result?.data?.filters?.map((element) => {
        arr = {
          ...arr,
          [element?.requestKey]: [],
        }
        checked = {
          ...checked,
          [element?.requestKey]: abc(element?.options),
        }
        return element
      })
      setFilteredValue(arr)
      setChecked(checked)
    }
  }, [isSuccess, isFetching, result, dispatch])

  function abc(option) {
    const test = option?.map((item) => {
      var data = {
        label: item,
        value: false,
      }
      return data
    })
    return test
  }
  const callApi = () => {
    let payload = {
      courseType: 'college',
      sortByType: sortValue,
      filters: filteredValue,
    }
    CourseTrigger(payload)
  }
  useEffect(() => {
    if (isCSuccess && !isCFetching) {
      dispatch(setCourse(CourseResult?.data?.courseDetails))
    }
  }, [isCFetching, isCSuccess, CourseResult, dispatch])
  useEffect(() => {
    let entries = Object.entries(check)
    entries.map(([key, val]) => {
      val?.map((data) => {
        if (data?.value) {
          setFilteredValue({
            ...filteredValue,
            [key]: [...filteredValue[key], data?.label],
          })
          return data?.label
        } else return ''
      })
      return key
    })
  }, [check, filteredValue])
  return (
    <>
      <div className={`position-fixed card ${active} notification`} style={{zIndex: '999'}}>
        <div className='row p-5 pt-0'>
          <div
            className='col-12 card rounded-0 border-secondary header-width border-8 px-8 py-5'
            style={{borderBottom: '2px solid', zIndex: '999', position: 'fixed'}}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className=''>Filter</h3>
              <div
                className='close-icon bg-light-dark'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  ToggleSidebar()
                }}
              >
                <i className='bi bi-x-lg  fw-bold'></i>
              </div>
            </div>
          </div>
          <div className='col-12 mt-20'>
            <div className='sd-body'>
              <div className='row align-items-center justify-content-between'>
                <div className='col-md-9 col-sm-12 mb-5'>
                  <h5>Filters (0 Selected)</h5>
                </div>

                <div className='col-12 mb-5'>
                  <input
                    type='text'
                    name=''
                    id=''
                    className='form-control fs-8'
                    placeholder='Search for Your Category and Language'
                  />
                </div>
              </div>
              <div className='col-12'>
                <div
                  className='w-100 me-3 d-flex justify-content-between align-items-center'
                  data-select2-id='select2-data-128-h9vb '
                >
                  <span className='filter-sort-span text-muted w-150px'>Sort By :</span>
                  <select
                    className='form-select form-select-solid fitler-select'
                    data-control='select2'
                    data-hide-search='true'
                    data-placeholder='Status'
                    data-kt-ecommerce-order-filter='status'
                    data-select2-id='select2-data-10-rb09'
                    tabindex='-1'
                    aria-hidden='true'
                    data-kt-initialized='1'
                    onChange={(e) => {
                      setSortValue(e.target.value)
                    }}
                  >
                    <option value='' data-select2-id='select2-data-133-6nb5'>
                      Select Option
                    </option>
                    {sortBy?.map((option) => {
                      return (
                        <option value={option?.key} data-select2-id='select2-data-133-6nb5'>
                          {option?.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div
                  className='d-flex justify-content-end align-items-center d-none'
                  data-kt-customer-table-toolbar='selected'
                >
                  <div className='fw-bold me-5'>
                    <span className='me-2' data-kt-customer-table-select='selected_count'></span>{' '}
                    Selected
                  </div>

                  <button
                    type='button'
                    className='btn btn-danger'
                    data-kt-customer-table-select='delete_selected'
                  >
                    Delete Selected
                  </button>
                </div>
              </div>
              {feedsData?.map((item, index) => {
                return (
                  <>
                    <div className='col-12 mb-5' key={index.toString()}>
                      <h3>{item?.name}</h3>
                    </div>
                    <div className='col-12 mb-5'>
                      <ul>
                        {item?.options?.map((option) => {
                          return (
                            <li>
                              <div className='sd-link'>
                                <div className='form-check form-check-custom form-check-solid'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id={item?.requestKey}
                                    onChange={(e) => {
                                      let value = []
                                      value = check[item?.requestKey]?.map((item, index) => {
                                        if (Object.values(item).includes(option)) {
                                          return {
                                            ...item,
                                            value: e.target?.checked,
                                          }
                                        }
                                        return item
                                      })
                                      setChecked({
                                        ...check,
                                        [item?.requestKey]: [...value],
                                      })

                                      // setFilteredValue({
                                      //   ...filteredValue,
                                      //   [item?.requestKey]: [
                                      //     ...filteredValue[item?.filteredValue],
                                      //     arr,
                                      //   ],
                                      // })
                                    }}
                                  />
                                  <label
                                    className='form-check-label'
                                    for='flexCheckDefault'
                                    style={{color: 'unset'}}
                                  >
                                    {option}
                                  </label>
                                </div>
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )
              })}
              <div className='col-12'>
                <div className='row align-items-center justify-content-center'>
                  <div className='col-md-6 col-sm-12 mb-5 text-center'>
                    <button className='btn w-100 btn-outline btn-outline-solid btn-outline-primary btn-active-light-primary'>
                      Clear
                    </button>
                  </div>
                  <div className='col-md-6 col-sm-12 mb-5 text-center'>
                    <button
                      className='btn btn-primary w-100 '
                      onClick={() => {
                        callApi()
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export function Feeds(props) {
  const {active, ToggleSidebar, type} = props
  const [feedsTrigger, result] = useLazyFeedsQuery()
  const {isSuccess, isFetching} = result
  const [subjectTrigger, SubjectResult] = useLazyAttendanceSubjectQuery()
  const {isSuccess: isSSuccess, isFetching: isSFetching} = SubjectResult
  useEffect(() => {
    if (type === 'feeds') {
      feedsTrigger({})
    } else if (type === 'subject') {
      subjectTrigger({})
    }
  }, [feedsTrigger, type, subjectTrigger])
  const [feedsData, setFeedsData] = useState([])
  useEffect(() => {
    if (isSuccess && !isFetching) {
      if (type === 'feeds') {
        setFeedsData(result?.data?.feeds)
      }
    }
  }, [isSuccess, isFetching, result, type])
  useEffect(() => {
    if (isSSuccess && !isSFetching) {
      if (type === 'subject') {
        setFeedsData(SubjectResult?.data?.data)
      }
    }
  }, [isSSuccess, isSFetching, SubjectResult, type])
  return (
    <>
      <div className={`position-fixed card ${active} notification`} style={{zIndex: '999'}}>
        <div className='row p-5 pt-0'>
          <div
            className='col-12 card rounded-0 border-secondary header-width border-8 px-8 py-5'
            style={{borderBottom: '2px solid', zIndex: '999', position: 'fixed'}}
          >
            <div className='d-flex justify-content-between align-items-center'>
              <h3 className=''>Subject</h3>
              <div
                className='close-icon bg-light-dark'
                style={{cursor: 'pointer'}}
                onClick={() => {
                  ToggleSidebar()
                }}
              >
                <i className='bi bi-x-lg  fw-bold'></i>
              </div>
            </div>
          </div>
          {type === 'feeds' ? (
            <>
              {feedsData?.map((item, index) => {
                return (
                  <div
                    className={`col-12 my-2 ${index === 0 ? 'mt-20' : 'mt-0'}`}
                    key={index.toString()}
                  >
                    <div
                      className={`p-5  notification-width fw-semibold mw-lg-400px text-start`}
                      data-kt-element='message-text'
                    >
                      <div className='d-flex align-items-center'>
                        {/* begin::Avatar */}
                        <div className='symbol symbol-50px me-5'>
                          {/* <img src={require('../../../assets/IconButton.png')} className='' alt='' /> */}
                          {item?.iconType === 'pin' ? (
                            <i className={`ki-solid ki-${item?.iconType} fs-2x text-muted`}></i>
                          ) : (
                            <i className={`fa-solid fa-${item?.iconType} fs-2x text-muted`}></i>
                          )}
                        </div>
                        {/* end::Avatar */}
                        {/* begin::Text */}
                        <div className='flex-grow-1'>
                          {item?.link ? (
                            <a
                              href={item?.link}
                              className=' fw-bold text-black'
                              style={{cursor: 'pointer'}}
                            >
                              {item?.title}
                            </a>
                          ) : (
                            <Link
                              to={`/${item?.internalLink}`}
                              className=' fw-bold text-black'
                              style={{cursor: 'pointer'}}
                            >
                              {item?.title}
                            </Link>
                          )}
                          <span className='d-block fw-semibold'>{item?.time}</span>
                        </div>
                        {/* end::Text */}
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          ) : (
            <>
              {feedsData.map((subject, index) => {
                return (
                  <div
                    className={`col-12 my-2 ${index === 0 ? 'mt-20' : 'mt-0'}`}
                    key={index.toString()}
                  >
                    <div className='d-flex align-items-center mb-8'>
                      {/* begin::Bullet */}
                      <div className='symbol symbol-50px me-5'>
                        {/* <span className='symbol-label bg-light-success'>
                  <KTIcon iconName='abstract-26' className='fs-2x text-success' />
                </span> */}
                        <div
                          className='symbol-label'
                          style={{
                            backgroundImage: `url(${subject?.iconLink})`,
                          }}
                        ></div>
                      </div>
                      {/* end::Bullet */}
                      {/* begin::Checkbox */}
                      <div className='d-flex w-100 flex-grow-1'>
                        <div className='d-flex flex-column'>
                          <a href='#Subject' className='text-dark text-hover-primary fs-6 fw-bold'>
                            {subject?.label}
                          </a>
                          <h4>{subject?.subjectName} Lectures</h4>
                          <span>{subject?.shortDescr}</span>
                        </div>
                      </div>

                      {/* end::Description */}
                      <div className='d-flex flex-column w-50 me-2'>
                        <div className='d-flex flex-stack mb-2'>
                          <span className='text-muted me-2 fs-7 fw-semibold'>
                            {subject?.attendance?.present}%
                          </span>
                        </div>
                        <div className='progress h-6px w-100'>
                          <div
                            className={`progress-bar bg-${
                              (subject?.attendance?.present / subject?.attendance?.total) * 100 < 65
                                ? 'danger'
                                : (subject?.attendance?.present / subject?.attendance?.total) *
                                    100 <
                                  80
                                ? 'warning'
                                : 'success'
                            }`}
                            role='progressbar'
                            style={{
                              width: `${
                                (subject?.attendance?.present / subject?.attendance?.total) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
      </div>
    </>
  )
}
