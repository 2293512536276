import React from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import AdmissionHeader from './Components/AdmissionHeader'
import FessStructure from './Components/FessStructure'
import Stream from './Components/Stream'
import Order from './Components/Order'
import Documents from './Components/Documents'

const admission: Array<PageLink> = [
    {
        title: 'Fess',
        path: '/Admission/fess',
        isSeparator: false,
        isActive: false,
    },
    {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
    },
]

export default function Admission() {
    return (
        <div>
            <Routes>
                <Route
                    element={
                        <>
                            <AdmissionHeader />
                            <Outlet />
                        </>
                    }
                >
                    <Route
                        path='Fess'
                        element={
                            <>
                                <PageTitle breadcrumbs={admission}>Fess Structure</PageTitle>
                                <FessStructure />
                            </>
                        }
                    />
                    <Route
                        path='Stream'
                        element={
                            <>
                                <PageTitle breadcrumbs={admission}>Change Stream</PageTitle>
                                <Stream />
                            </>
                        }
                    />
                    <Route
                        path='Order'
                        element={
                            <>
                                <PageTitle breadcrumbs={admission}>Order</PageTitle>
                                <Order />
                            </>
                        }
                    />
                    <Route
                        path='Documents'
                        element={
                            <>
                                <PageTitle breadcrumbs={admission}>Documents</PageTitle>
                                <Documents />
                            </>
                        }
                    />
                    <Route index element={<Navigate to='/Admission/fess' />} />
                </Route>
            </Routes>
        </div>
    )
}
