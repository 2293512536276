import React from 'react'
import {useNavigate} from 'react-router-dom'

const JDetails = (props) => {
  const {item} = props
  const navigate = useNavigate()
  function redirect(jobProgress) {
    navigate(`/job/jobdetails?progress=${jobProgress}`)
  }
  return (
    <div
      className='border border-1 border-secondary rounded-4 py-10 px-5 my-5'
      onClick={() => redirect(item?.dbId)}
    >
      <div className='row justify-content-around  ' style={{cursor: 'pointer'}}>
        <div className='col-md-2 col-sm-12   text-start'>
          <div className='job-image mx-auto' style={{height: '100px', width: '100px'}}>
            <img src={item?.companyIcon} alt='job' className='h-100 w-100 ' />
          </div>
        </div>
        <div className='col-md-10 col-sm-12  my-5 my-lg-0 '>
          <div className='d-flex justify-content-between flex-wrap align-items-center '>
            <h4 className='fs-4'>{item?.companyName}</h4>
            <span
              className='fs-6 px-1 fw-bold rounded'
              style={{backgroundColor: `${item?.jobTagHex}`}}
            >
              <span className='mx-2 text-light '>{item?.jobTag}</span>
            </span>
          </div>
          <span className='fw-bold fs-2'>{item?.title}</span>
          <div>
            <div className='row'>
              <div className='col-lg-3 col-sm-6'>
                <i className='bi bi-geo-alt'></i>
                <span className='fs-6 mx-1'>{item?.location}</span>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <i className='bi bi-clock'></i>
                <span className='fs-6 mx-1'>{item?.contractType}</span>
              </div>
              {item?.salary ? (
                <div className='col-lg-3 col-sm-6'>
                  <i className='bi bi-currency-dollar'></i>
                  <span className='fs-6 mx-1'>{item?.salary}</span>
                </div>
              ) : null}

              <div className='col-lg-3 col-sm-6'>
                <i className='bi bi-calendar'></i>
                <span className='fs-6 mx-1'>{item?.publishedAt}</span>
              </div>
            </div>
          </div>
          <h4>Experience : {item?.experienceLevel}</h4>
          <h4 className='my-2 fs-7'>Skills :{item?.skills.toString()}</h4>
          <h4 className='my-2 fs-7'>Industry :{item?.industry}</h4>
          <p className='my-2'>{item?.jobSummary}</p>
        </div>
      </div>
    </div>
  )
}

export default JDetails
