import { createSlice } from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
    // variables
    course: [],
    filter: []
}

export const CourseSlice = createSlice({
    name: 'CourseSlice',
    initialState,
    reducers: {
        setCourse: (state, action) => {
            state.course = action.payload
        },
        setFilter: (state, action) => {
            state.filter = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    setCourse,
    setFilter
} = CourseSlice.actions
export default CourseSlice.reducer