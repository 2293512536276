import { configureStore } from '@reduxjs/toolkit'
import authSlice from './Auth'
import assignmentSlice from './AssignmentSlice'
import EventSlice from './EventSlice'
import achievementSlice from './achivementSlice'
import CourseSlice from './CourseSlice'
import UserDetailSlice from './UserDetailSlice'
import { allApiSlice } from '../Api/allApi'
// import depositReducer from './depositSlice'
// import { userApiSlice } from '../api/userApi'
// import { publicApiSlice } from '..//api/allApi'
export const store = configureStore({
    reducer: {
        // globalReducer,
        // depositReducer,
        // [publicApiSlice.reducerPath]: publicApiSlice.reducer,
        authSlice,
        assignmentSlice,
        EventSlice,
        achievementSlice,
        CourseSlice,
        UserDetailSlice,
        [allApiSlice.reducerPath]: allApiSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            allApiSlice.middleware,
        ),
})

