import clsx from 'clsx'
import {  toAbsoluteUrl } from '../../../helpers'
import { Search, ThemeModeSwitcher, HeaderUserMenu } from '../../../partials'
import { Feeds } from '../../../../app/modules/Notification/Notification'
import { useState } from 'react'
const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'
const Navbar = () => {
  // const { config } = useLayout()
  const [show, setshow] = useState(false);
  const ToggleSidebar = () => {
    show === true ? setshow(false) : setshow(true)
  }
  return (
    <div className='app-navbar flex-shrink-0'>
      <div className={clsx('app-navbar-item align-items-stretch m-auto', itemClass)}>
        <Search />
      </div>
      <div className={'app-navbar-item ms-1 ms-lg-3'}>
        <div className="position-relative btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-40px h-40px" id="kt_drawer_chat_toggle" onClick={ToggleSidebar}>
          <i className="ki-duotone ki-message-text-2 fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span></i>
          {/* <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span> */}
        </div>
        <div
          className={`sidebar-overlay ${show === true ? 'active' : ''}`}
          onClick={ToggleSidebar}
        ></div>
        <Feeds active={show ? 'notification-active' : ""} type="feeds" ToggleSidebar={ToggleSidebar} />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
      </div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <img src={toAbsoluteUrl('/media/avatars/300-2.jpg')} alt='' />
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}
export { Navbar }
