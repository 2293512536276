/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { Feeds } from '../../../../app/modules/Notification/Notification';
type Props = {
  className: string,
  data: any
}

const ListsWidget2: React.FC<Props> = ({ className, data }) => {
  const [show, setshow] = useState(false);
  const ToggleSidebar = () => {
    show === true ? setshow(false) : setshow(true)
  }
  return (
    <div className={`card ${className}`} style={{ position: "unset" }}>
      {/* begin::Header */}
      <div className='card-header border-0'>
        <h3 className='card-title fw-bold text-dark'>Feeds</h3>
        <div className='card-toolbar'>
          {/* begin::Menu */}
          <div className="position-relative btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-40px h-40px" id="kt_drawer_chat_toggle" onClick={ToggleSidebar}>
            <i className="ki-duotone ki-message-text-2 fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span></i>
          </div>
          <div
            className={`sidebar-overlay ${show === true ? 'active' : ''}`}
            onClick={ToggleSidebar}
            style={{ zIndex: "100", height: "110vh", top: "-10px" }}
          ></div>
          <Feeds active={show ? 'notification-active' : ""}  type='feeds' ToggleSidebar={ToggleSidebar}/>

          {/* end::Menu */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      {
        !data ? <div className='card-body pt-5 text-center'><i className="fa-solid fa-circle-notch text-primary fa-spin  p-10" style={{ fontSize: "30px", color: "unset" }}  ></i> </div> : <div className='card-body '>
          {/* begin::Item */}
          {
            data?.map((item: any, index: any) => {
              return (
                <div className='d-flex align-items-center mb-7' key={index.toString()}>
                  {/* begin::Avatar */}
                  <div className='symbol symbol-50px me-5'>
                    {/* <img src={require('../../../assets/IconButton.png')} className='' alt='' /> */}
                    {
                      item?.iconType === "pin" ?
                        <i className={`ki-solid ki-${item?.iconType} fs-2x text-muted`}></i>
                        :
                        <i className={`fa-solid fa-${item?.iconType} fs-2x text-muted`}></i>
                    }

                  </div>
                  {/* end::Avatar */}
                  {/* begin::Text */}
                  <div className='flex-grow-1'>
                    {
                      item?.link ? <a href={item?.link} className='text-dark fw-bold text-hover-primary' style={{ cursor: "pointer" }}>
                        {item?.title}
                      </a> : <Link to={`/${item?.internalLink}`} className='text-dark fw-bold text-hover-primary' style={{ cursor: "pointer" }}>
                        {item?.title}
                      </Link>
                    }
                    <span className='text-muted d-block fw-semibold'>{item?.time}</span>
                  </div>
                  {/* end::Text */}
                </div>
              )
            })
          }
          {/* end::Item */}
        </div>
      }

      {/* end::Body */}
    </div >
  )
}

export { ListsWidget2 }
