import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
// import Blog from '../modules/Blog/Blog'
// import ViewBlog from '../modules/Blog/ViewBlog'
// import CreateBlog from '../modules/Blog/CreateBlog'
import Setting from '../modules/Settings/Setting'
import Job from '../modules/jobs/Job'
import JobDetails from '../modules/jobs/Components/JobDetails'
import Admission from '../modules/Admission/Admission'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const MyProfilePage = lazy(() => import('../modules/MyProfile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  // const Courses = lazy(() => import('../pages/CustomPages/courses/Courses'))
  const CoursesPage = lazy(() => import('../modules/courses/CoursesPage'))
  const AssignmentPage = lazy(() => import('../modules/assignment/AssignmentPage'))
  const AssessmentPage = lazy(() => import('../modules/assessment/AssessmentPage'))
  const EventsPage = lazy(() => import('../modules/events/EventsPage'))
  const EventsDetails = lazy(() => import('../modules/events/EventsDetails'))
  const AchievementsPage = lazy(() => import('../modules/achievements/AchievementsPage'))
  const AttendancePage = lazy(() => import('../modules/attendance/AttendancePage'))
  const BlogPage = lazy(() => import('../modules/Blog/BlogPage'))
  const CourseLearning = lazy(() => import('../pages/CustomPages/courses/CourseLearning'))
  // const CourseDetails = lazy(() => import('../pages/CustomPages/courses/CourseDetails'))
  const ExploreCourse = lazy(() => import('../pages/CustomPages/courses/ExploreCourse'))
  const MyCourses = lazy(() => import('../pages/CustomPages/courses/MyCourses'))
  const Faculty = lazy(() => import('../pages/CustomPages/courses/courseFaculty/Faculty'))
  const SubjectDetails = lazy(
    () => import('../pages/CustomPages/courses/courseFaculty/SubjectDetails')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />z{/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='courses/*'
          element={
            <SuspensedView>
              <CoursesPage />
            </SuspensedView>
          }
        />
        <Route
          path='/crafted/account/myProfile'
          element={
            <SuspensedView>
              <MyProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='/courses/academic/faculty'
          element={
            <SuspensedView>
              <Faculty />
            </SuspensedView>
          }
        />
        <Route
          path='/subjectdetails'
          element={
            <SuspensedView>
              <SubjectDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/Admission/*'
          element={
            <SuspensedView>
              <Admission />
            </SuspensedView>
          }
        />
        <Route
          path='/courses/myCourse'
          element={
            <SuspensedView>
              <MyCourses />
            </SuspensedView>
          }
        />
        <Route
          path='/courses/academic/Subject'
          element={
            <SuspensedView>
              <SubjectDetails />
            </SuspensedView>
          }
        />
        <Route
          path='/courses/ExploreCourse'
          element={
            <SuspensedView>
              <ExploreCourse />
            </SuspensedView>
          }
        />
        <Route
          path='/courses/myCourse/coursevideo'
          element={
            <SuspensedView>
              {/* <CourseDetails /> */}
              <CourseLearning />
            </SuspensedView>
          }
        />
        <Route
          path='/courses/collageCourse/coursedetails'
          element={
            <SuspensedView>
              <CourseLearning />
            </SuspensedView>
          }
        />
        {/* <Route
          path='assignment'
          element={
            <SuspensedView>
              <AssignmentPage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='assignment/*'
          element={
            <SuspensedView>
              <AssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='events'
          element={
            <SuspensedView>
              <EventsPage />
            </SuspensedView>
          }
        />
        <Route
          path='/events/eventsDetails'
          element={
            <SuspensedView>
              <EventsDetails />
            </SuspensedView>
          }
        />
        <Route
          path='assessments/*'
          element={
            <SuspensedView>
              <AssessmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='achievements/*'
          element={
            <SuspensedView>
              <AchievementsPage />
            </SuspensedView>
          }
        />
        <Route
          path='job/*'
          element={
            <SuspensedView>
              <Job />
            </SuspensedView>
          }
        />
        <Route
          path='/job/jobdetails'
          element={
            <SuspensedView>
              <JobDetails />
            </SuspensedView>
          }
        />
        <Route
          path='blog/*'
          element={
            <SuspensedView>
              <BlogPage />
            </SuspensedView>
          }
        />
        <Route
          path='attendance'
          element={
            <SuspensedView>
              <AttendancePage />
            </SuspensedView>
          }
        />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='blog'
          element={
            <SuspensedView>
              <Blog />
            </SuspensedView>
          }
        />
        <Route
          path='blog/viewBlog'
          element={
            <SuspensedView>
              <ViewBlog />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='blog/createBlog'
          element={
            <SuspensedView>
              <CreateBlog />
            </SuspensedView>
          }
        /> */}
        {/* <Route
          path='/Setting'
          element={
            <SuspensedView>
              <Setting />
            </SuspensedView>
          }
        /> */}
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
