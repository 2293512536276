export const setUserDetailInLocalStorage = (data: any) => {
    localStorage.setItem('_token', data.token)
    localStorage.setItem('user', JSON.stringify(data));
}
export const getUserDetailInLocalStorage = () => {
    localStorage.getItem('user');
}
export const getTokenInLocalStorage = () => {
    var abc: any = localStorage.getItem('_token')
    var token: any = abc
    return token
}