import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageTitle} from '../../../_metronic/layout/core'
import JobCard from './JobCard'
import {JobHeader} from './JobHeader'

const accountBreadCrumbs = [
  {
    title: 'All',
    path: '/job/view',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const Job = () => {
  return (
    <Routes>
      <Route
        element={
          <>
            <JobHeader />
            <Outlet />
          </>
        }
      >
        <Route
          path='view'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>View</PageTitle>
              <JobCard />
            </>
          }
        />
        <Route
          path='applied'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Applied</PageTitle>
              <JobCard />
            </>
          }
        />
        <Route
          path='shortlist'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Short Listed</PageTitle>
              <JobCard />
            </>
          }
        />
        <Route
          path='rejected'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>Rejected</PageTitle>
              <JobCard />
            </>
          }
        />
        <Route
          path='onhold'
          element={
            <>
              <PageTitle breadcrumbs={accountBreadCrumbs}>On Hold</PageTitle>
              <JobCard />
            </>
          }
        />
        <Route index element={<Navigate to='/job/view' />} />
      </Route>
    </Routes>
  )
}

export default Job
