import { createSlice } from '@reduxjs/toolkit'
// import { getUserDetailInLocalStorage,getTokenInLocalStorage } from '../Utiles/LocalStorage'
const initialState = {
    // variables
    internalEvents:[],
    stringedEvents:[],
    otherEvents:[],
}

export const EventSlice = createSlice({
    name: 'EventSlice',
    initialState,
    reducers: {
        setInternalEvents: (state, action) => {
            state.internalEvents = action.payload
        },
        setStringedEvents: (state, action) => {
            state.stringedEvents = action.payload
        },
        setOtherEvents: (state, action) => {
            state.otherEvents = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const {
    setInternalEvents,
    setStringedEvents,
    setOtherEvents,
} = EventSlice.actions
export default EventSlice.reducer